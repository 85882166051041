<template>
	<v-col cols="12" class="d-flex flex-column align-self-stretch pa-0">
		<v-card flat tile :loading="commandsLoading" class="rounded-b-xl">
			<v-row class="pa-11 pb-3 justify-start align-center">
				<h3>Text Commands</h3>
				<NewCommand @NewCommandAdded="getCommands"/>
			</v-row>

			<v-simple-table fixed-header class="pa-4">
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">Command</th>
							<th class="text-left">Message</th>
							<th class="text-left"></th>
						</tr>
					</thead>
					
					<tbody>
						<EditCommand
							v-for="command in commands" 
							:key="command.id"
							:command="command" 
							@CommandDeleted="getCommands"
							@CommandEdited="getCommands"
						/>
					</tbody>
				</template>
			</v-simple-table>
		</v-card>
	</v-col>
</template>

<script>
import EditCommand from './EditCommand.vue';
import NewCommand from './NewCommand.vue';

export default {
	components: {
		NewCommand,
		EditCommand
	},

	data() {
		return {
			tab: 0,
			commandsLoading: true,
			commands: [],
		}
	},

	async mounted() {
		await this.getCommands();
	},

	methods: {
		getCommands: async function() {
			try {
				const request = await fetch(`${this.$store.state.base_url}/commands`, {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});

				const res = await request.json();

				if (request.ok) {
					if (res.commands) {
						this.commands = res.commands;
					}
				}

				if (request.status == 500 || request.status == 401) {
					if (res.error && this.$store.state.error_types.includes(res.error)) {
						this.sessionExpiredHandler(res);
						return;
					}

					throw (res);
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
				});
			}
			finally {
				this.commandsLoading = false;
			}
		},
	},
}
</script>